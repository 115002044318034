<template>
  <FullPage>
    <div id="dashboard" class="">
      <!-- SUB HEADER -->
      <TabBar :btns="btns" />
      <!--  -->
      <div
        class="fixed left-0 right-0 top-24 bottom-0 grid grid-cols-1 xl:grid-cols-3 gap-4 p-5 overscroll-y-auto overflow-y-auto"
      >
        <div
          class="p-3 text-center items-center shadow"
          v-for="n in 29"
          :key="n"
        >
          {{ n }}
        </div>
      </div>
    </div>
  </FullPage>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {
    TabBar: () => import('@/_components/TabBar.vue'),
    FullPage: () => import('@/_templates/FullPage.vue')
  },
  data: () => ({
    btns: [
      {
        title: 'Dashboard',
        route: 'dashboard'
      },
      {
        title: 'Attività',
        route: 'activities'
      },
      {
        title: 'Suggerimenti',
        route: 'hints'
      }
    ]
  })
}
</script>
